import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import domtoimage from "dom-to-image";
import "../css/Reporte.css";
import ProgressBar from "./ProgressBar";
import ProgressBarTotal from "./ProgressBar_Total";
import provincia1 from "../images/mapa_elqui.png";
import provincia2 from "../images/mapa_limari.png";
import provincia3 from "../images/mapa_choapa.png";
import mapa1 from "../images/mapa_color_elqui.png";
import mapa2 from "../images/mapa_color_limari.png";
import mapa3 from "../images/mapa_color_choapa.png";
import Modal from "react-modal";

function Reporte() {
  const [elqui, setElqui] = useState([]);
  const [limari, setLimari] = useState([]);
  const [choapa, setChoapa] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const modalContentRef = useRef(null);

  const reportRef = useRef(null); // Reference to the Reporte component

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };
  const fetchElqui = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/reporte.php`
    );
    setElqui(response.data);
  };

  const fetchLimari = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/reporte_2.php`
    );
    setLimari(response.data);
  };

  const fetchChoapa = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/reporte_3.php`
    );
    setChoapa(response.data);
  };

  const handleBodyScroll = (isOpen) => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Deshabilita el desplazamiento
    } else {
      document.body.style.overflow = "auto"; // Habilita el desplazamiento
    }
  };

  useEffect(() => {
    handleBodyScroll(isOpen);

    fetchElqui();
    fetchLimari();
    fetchChoapa();

    return () => {
      document.body.style.overflow = "auto"; // Asegura que el desplazamiento se habilite al salir
    };
  }, [isOpen]);

  const volumenActualElqui = elqui.reduce(
    (acc, current) => acc + parseFloat(current.actual),
    0
  );
  const volumenActualLimari = limari.reduce(
    (acc, current) => acc + parseFloat(current.actual),
    0
  );
  const volumenActualChoapa = choapa.reduce(
    (acc, current) => acc + parseFloat(current.actual),
    0
  );
  const totalVolumenActual =
    volumenActualElqui + volumenActualLimari + volumenActualChoapa;

  const volumenTotalElqui = elqui.reduce(
    (acc, current) => acc + parseFloat(current.total),
    0
  );
  const volumenTotalLimari = limari.reduce(
    (acc, current) => acc + parseFloat(current.total),
    0
  );
  const volumenTotalChoapa = choapa.reduce(
    (acc, current) => acc + parseFloat(current.total),
    0
  );
  const totalVolumenTotal =
    volumenTotalElqui + volumenTotalLimari + volumenTotalChoapa;

  const downloadImage = () => {
    if (modalContentRef.current) {
      domtoimage
        .toPng(modalContentRef.current, {
          filter: (node) => {
            // Excluir nodos con la clase 'no-capture'
            return !node.classList || !node.classList.contains("no-capture");
          },
        })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "Reporte_situación_hídrica_embalses.png";
          link.href = dataUrl;
          link.click();
        })
        .catch((error) => {
          console.error("Error generating image:", error);
        });
    }
  };

  return (
    <div>
      <div className="reporte">
        <h1>Reporte Situación hídrica embalses</h1>
        <div className="reporteTotal">
          <h3 className="totalRegional">Total Volumen Regional</h3>
          <label className="volumenActualTotal">
            Volumen Actual: {totalVolumenActual.toLocaleString()} MM
            <sup>3</sup>
          </label>
          <ProgressBarTotal
            completed={((totalVolumenActual / totalVolumenTotal) * 100).toFixed(
              1
            )}
          />
          <div className="volumenTotalContainer">
            <label className="volumenTotal">
              Volumen Total: {totalVolumenTotal.toLocaleString()} MM<sup>3</sup>
            </label>
          </div>
        </div>
        <div className="reporteElqui" onClick={handleOpenModal}>
          <h3>Provincia de Elqui</h3>
          <img src={provincia1}></img>
          {elqui.map((elqui, index) => (
            <div key={index}>
              <h4>{elqui.embalse}</h4>
              <label className="volumenActual">
                Volumen Actual: {elqui.actual} MM
                <sup>3</sup>
              </label>
              <ProgressBar
                completed={((elqui.actual / elqui.total) * 100).toFixed(1)}
              />
              <div className="volumenTotalContainer">
                <label className="volumenTotal">
                  Volumen Total: {Math.floor(elqui.total)} MM
                  <sup>3</sup>
                </label>
              </div>
            </div>
          ))}
          <label className="estadoCuenca">
            Estado de Cuenca: {(volumenActualElqui * 1000000).toLocaleString()}{" "}
            m<sup>3</sup>
          </label>
        </div>
        <div className="reporteLimari" onClick={handleOpenModal}>
          <h3>Provincia de Limari</h3>
          <img src={provincia2}></img>
          {limari.map((limari, index) => (
            <div key={index}>
              <h4>{limari.embalse}</h4>
              <label className="volumenActual">
                Volumen Actual: {limari.actual} MM
                <sup>3</sup>
              </label>
              <ProgressBar
                completed={((limari.actual / limari.total) * 100).toFixed(1)}
              />
              <div className="volumenTotalContainer">
                <label className="volumenTotal">
                  Volumen Total: {Math.floor(limari.total)} MM<sup>3</sup>
                </label>
              </div>
            </div>
          ))}
          <label className="estadoCuenca">
            Estado de Cuenca: {(volumenActualLimari * 1000000).toLocaleString()}{" "}
            m<sup>3</sup>
          </label>
        </div>
        <div className="reporteChoapa" onClick={handleOpenModal}>
          <h3>Provincia de Choapa</h3>
          <img src={provincia3}></img>
          {choapa.map((choapa, index) => (
            <div key={index}>
              <h4>{choapa.embalse}</h4>
              <label className="volumenActual">
                Volumen Actual: {choapa.actual} MM
                <sup>3</sup>
              </label>
              <ProgressBar
                completed={((choapa.actual / choapa.total) * 100).toFixed(1)}
              />
              <div className="volumenTotalContainer">
                <label className="volumenTotal">
                  Volumen Total: {Math.floor(choapa.total)} MM<sup>3</sup>
                </label>
              </div>
            </div>
          ))}
          <label className="estadoCuenca">
            Estado de Cuenca: {(volumenActualChoapa * 1000000).toLocaleString()}{" "}
            m<sup>3</sup>
          </label>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={true}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            backgroundColor: "transparent",
            padding: "0px",
            borderRadius: "10px",
            width: "100%",
            height: "100%",
            margin: "auto",
            inset: "0px",
            border: "none",
          },
        }}>
        <div className="contenedorFondoImagen">
          <div className="fondoImagenModal" ref={modalContentRef}>
            <div className="modalReporte">
              <h1>Reporte situación hídrica embalses</h1>
              <h2>Región de Coquimbo</h2>
              <div className="modalReporteTotal">
                <p>Total Volumen Regional</p>
                <label>
                  Volumen Actual: {totalVolumenActual.toLocaleString()} MM
                  <sup>3</sup>
                </label>
                <ProgressBarTotal
                  completed={(
                    (totalVolumenActual / totalVolumenTotal) *
                    100
                  ).toFixed(1)}
                />
                <label>
                  Volumen Total: {totalVolumenTotal.toLocaleString()} MM
                  <sup>3</sup>
                </label>
              </div>
              <h3>Estado de las Cuencas</h3>
              <div className="modalProvincias">
                <div>
                  <h3 className="modalNombreProvincia">
                    Provincia <strong>Elqui</strong>
                  </h3>
                  <h4>Total Actumulado</h4>
                  <h3 className="modalTotalAcumulado">
                    {(volumenActualElqui * 1000000).toLocaleString()} m
                    <sup>3</sup>
                  </h3>
                  <div className="contenedorMapa">
                    <img src={mapa1}></img>
                  </div>
                  {elqui.map((elqui, index) => (
                    <div key={index}>
                      <h2>{elqui.embalse}</h2>
                      <label className="volumenActualModal">
                        Volumen Actual: {elqui.actual} MM
                        <sup>3</sup>
                      </label>
                      <ProgressBarTotal
                        completed={((elqui.actual / elqui.total) * 100).toFixed(
                          1
                        )}
                      />
                      <div className="volumenTotalContainer">
                        <label className="volumenTotal">
                          Volumen Total: {Math.floor(elqui.total)} MM
                          <sup>3</sup>
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <h3 className="modalNombreProvincia_limari">
                    Provincia <strong>Limarí</strong>
                  </h3>
                  <h4>Total Actumulado</h4>
                  <h3 className="modalTotalAcumulado_limari">
                    {(volumenActualLimari * 1000000).toLocaleString()} m
                    <sup>3</sup>
                  </h3>
                  <div className="contenedorMapa">
                    <img src={mapa2}></img>
                  </div>
                  {limari.map((limari, index) => (
                    <div key={index}>
                      <h2>{limari.embalse}</h2>
                      <label className="volumenActualModal">
                        Volumen Actual: {limari.actual} MM
                        <sup>3</sup>
                      </label>
                      <ProgressBarTotal
                        completed={(
                          (limari.actual / limari.total) *
                          100
                        ).toFixed(1)}
                      />
                      <div className="volumenTotalContainer">
                        <label className="volumenTotal">
                          Volumen Total: {Math.floor(limari.total)} MM
                          <sup>3</sup>
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <h3 className="modalNombreProvincia">
                    Provincia <strong>Choapa</strong>
                  </h3>
                  <h4>Total Actumulado</h4>
                  <h3 className="modalTotalAcumulado">
                    {(volumenActualChoapa * 1000000).toLocaleString()} m
                    <sup>3</sup>
                  </h3>
                  <div className="contenedorMapa">
                    <img src={mapa3}></img>
                  </div>
                  {choapa.map((choapa, index) => (
                    <div key={index}>
                      <h2>{choapa.embalse}</h2>
                      <label className="volumenActualModal">
                        Volumen Actual: {choapa.actual} MM
                        <sup>3</sup>
                      </label>
                      <ProgressBarTotal
                        completed={(
                          (choapa.actual / choapa.total) *
                          100
                        ).toFixed(1)}
                      />
                      <div className="volumenTotalContainer">
                        <label className="volumenTotal">
                          Volumen Total: {Math.floor(choapa.total)} MM
                          <sup>3</sup>
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="modalButtons">
              <button className="no-capture" onClick={handleCloseModal}>
                Cerrar
              </button>
              <button className="no-capture" onClick={downloadImage}>
                Descargar
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Reporte;
