import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import "../css/ProgressBar.css";

const SegmentedProgressBar = ({ completed }) => {
  const segments = [];
  for (let i = 0; i < 10; i++) {
    segments.push(<div key={i} className="progress-bar-segment"></div>);
  }

  const getColor = (value) => {
    if (value < 20) return "red";
    if (value < 40) return "orange";
    return "green";
  };

  return (
    <div className="segmented-progress-bar-container">
      <div className="segmented-progress-bar">
        <ProgressBar
          className="progress-bar"
          completed={completed}
          height="10px"
          bgColor={"white"}
          isLabelVisible={false}
          baseBgColor={"gray"}
          padding="3px"
          borderRadius="100px"
        />
        <div className="progress-bar-overlay">{segments}</div>
      </div>
      <div
        className="progress-percentage"
        style={{ color: getColor(completed) }}>
        {completed}%
      </div>
    </div>
  );
};

export default SegmentedProgressBar;
