import React, { useState } from "react";
import "../css/Panel_Visualizadores.css";
import axios from "axios";
import borde1 from "../images/bordes_region.png";
import borde2 from "../images/bordes_elqui.png";
import borde3 from "../images/bordes_limari.png";
import borde4 from "../images/bordes_choapa.png";

function Panel_Visualizadores() {
  // Estado para el URL del iframe
  const [iframeUrl, setIframeUrl] = useState(
    "https://www.arcgis.com/apps/dashboards/51e1eea818a0439e90919a2b0cacdf86"
  );

  // Función para cambiar el URL al hacer clic en una provincia
  const cambiarIframe = (url) => {
    setIframeUrl(url);
  };

  return (
    <div className="panelVisualizadores">
      <h1>Monitoreo de uso de suelo agrícola</h1>
      <h2>2024</h2>
      <div className="selectorZona">
        <div
          className="botonRegion"
          onClick={() =>
            cambiarIframe(
              "https://www.arcgis.com/apps/dashboards/51e1eea818a0439e90919a2b0cacdf86"
            )
          }>
          <div className="region">
            <img src={borde1}></img>
          </div>
          <h1>Región de Coquimbo, Chile</h1>
        </div>
        <div className="botonesProvincia">
          <div
            className="provincia1"
            onClick={() =>
              cambiarIframe(
                "https://www.arcgis.com/apps/dashboards/cbf9a55bca114c98b9fbf11a1e8a8fbb"
              )
            }>
            <img src={borde2}></img>
            <h1>Provincia Elqui</h1>
          </div>
          <div
            className="provincia2"
            onClick={() =>
              cambiarIframe(
                "https://www.arcgis.com/apps/dashboards/5c39979b38e34ed1bd73eb6bce9d1288"
              )
            }>
            <img src={borde3}></img>
            <h1>Provincia Limarí</h1>
          </div>
          <div
            className="provincia3"
            onClick={() =>
              cambiarIframe(
                "https://www.arcgis.com/apps/dashboards/3a20c2b8948c46ab83936114b0413d29"
              )
            }>
            <img src={borde4}></img>
            <h1>Provincia Choapa</h1>
          </div>
        </div>
      </div>
      <iframe src={iframeUrl} loading="lazy"></iframe>
    </div>
  );
}

export default Panel_Visualizadores;
