import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import "../../css/Crud.css";

Modal.setAppElement("#root"); // Asegúrate de que el ID coincida con el de tu elemento root en el HTML

function App() {
  const [presentacion, setPresentacion] = useState("");
  const [historia, setHistoria] = useState("");
  const [infraestructura, setInfraestructura] = useState(""); // Estado para la fecha
  const [que_hacemos, setQue_hacemos] = useState(""); // Estado para la fecha
  const [items, setItems] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Estado para el modal de edición y los campos del formulario de edición
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [editPresentacion, setEditPresentacion] = useState("");
  const [editHistoria, setEditHistoria] = useState("");
  const [editInfraestructura, setEditInfraestructura] = useState("");
  const [editQue_hacemos, setEditQue_hacemos] = useState("");
  const [editId, setEditId] = useState(null);

  const [previewUrl, setPreviewUrl] = useState(null);

  //Llama al método GET para recibir los datos de la base de datos
  const fetchItems = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/Crud_Que_Hacemos.php`
    );
    setItems(response.data);
  };

  //Muestra la tabla al iniciar la página
  useEffect(() => {
    fetchItems();
  }, []);

  //Maneja la subida de datos a la base de datos
  const handleSubmit = async (e) => {
    e.preventDefault(); // Previene el comportamiento por defecto del formulario

    // Crea un objeto FormData y lo rellena con la información del formulario
    const formData = new FormData();
    formData.append("presentacion", presentacion);
    formData.append("historia", historia);
    formData.append("infraestructura", infraestructura);
    formData.append("que_hacemos", que_hacemos);
    formData.append("action", "new");

    // Crear un objeto para almacenar los datos de FormData y poder imprimirlos en consola
    const formDataObj = {};
    formData.forEach((value, key) => {
      formDataObj[key] = value;
    });

    //Envía el FormData a al backend
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Crud_Que_Hacemos.php`,
        formData
      );

      console.log(response.data); // Imprime la respuesta exitosa
      console.log(formDataObj);
    } catch (error) {
      console.error("Error al enviar los datos:", error); // Imprime la respuesta fallida
      console.log(formDataObj);
    }
    fetchItems(); // Actualiza la tabla
    closeModal(); // Cierra el modal
  };

  // Función para abrir el modal de edición y establecer los valores de los inputs
  const handleEdit = (item) => {
    setEditPresentacion(item.presentacion);
    setEditHistoria(item.historia);
    setEditInfraestructura(item.infraestructura);
    setEditQue_hacemos(item.que_hacemos);
    setEditId(item.id);
    openEditModal();
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/Crud_Que_Hacemos.php`,
        {
          editId,
          editPresentacion,
          editHistoria,
          editInfraestructura,
          editQue_hacemos,
        }
      );
      console.log(response.data);
      console.log(editId);
      console.log(editPresentacion);
      console.log(editHistoria);
      console.log(editInfraestructura);
      console.log(editQue_hacemos);
    } catch (error) {
      console.error("Error al enviar los datos:", error);
    }

    fetchItems(); // Actualiza la tabla
    closeEditModal(); // Cierra el modal de edición
  };

  const moveItem = (index, direction) => {
    let newItems = [...items];
    const targetIndex = direction === "up" ? index - 1 : index + 1;

    // Si el índice de destino está fuera de los límites, no hacemos nada
    if (targetIndex < 0 || targetIndex >= newItems.length) return;

    // Intercambiar los elementos
    const temp = newItems[index];
    newItems[index] = newItems[targetIndex];
    newItems[targetIndex] = temp;

    setItems(newItems);

    // Enviar el nuevo orden al backend
    updateOrderInDatabase(newItems);
  };

  const updateOrderInDatabase = async (newItems) => {
    const formData = new FormData();
    formData.append("action", "update_order");
    newItems.forEach((item, index) => {
      formData.append(`items[${index}][id]`, item.id);
      formData.append(`items[${index}][order]`, index);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Crud_Que_Hacemos.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error al actualizar el orden:", error);
    }
  };

  //Maneja la eliminación de datos de la base de datos
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/Crud_Que_Hacemos.php`,
        {
          data: { id },
        }
      );
      console.log(response.data); // Imprime la respuesta exitosa
    } catch (error) {
      console.error("Error al eliminar los datos:", error); // Imprime la respuesta fallida
    }
    fetchItems(); // Actualiza la tabla
  };

  //Abre el modal
  const openModal = () => {
    setModalIsOpen(true);
    setPreviewUrl(null);
  };

  //Cierra el modal
  const closeModal = () => {
    setModalIsOpen(false);
    setPreviewUrl(null);
  };
  //Abre el modal Edit
  const openEditModal = () => {
    setEditModalIsOpen(true);
    setPreviewUrl(null);
  };

  //Cierra el modal Edit
  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setPreviewUrl(null);
  };

  const textos = {
    titulo: "¿Qué Hacemos?",
    addButton: "Añadir Información",
    columna1: "Presentación",
    columna2: "Historia",
    columna3: "Infraestructura",
    columna4: "¿Qué Hacemos?",
  };

  return (
    <div className="App">
      <h1>{textos.titulo}</h1>
      <button className="añadirFila" onClick={openModal}>
        {textos.addButton}
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Formulario de CRUD"
        className="modalDashboard"
        overlayClassName="modal-overlay">
        <form onSubmit={handleSubmit}>
          <h1>{textos.addButton}</h1>
          <div>
            <label>{textos.columna1}:</label>
            <textarea
              type="text"
              onChange={(e) => setPresentacion(e.target.value)} // Asigna el valor ingresado a la variable título
              required
            />
          </div>
          <div>
            <label>{textos.columna2}:</label>
            <textarea
              type="text"
              onChange={(e) => setHistoria(e.target.value)} // Asigna el valor ingresado a la variable descripción
              required
            />
          </div>
          <div>
            <label>{textos.columna3}:</label>
            <textarea
              type="text"
              onChange={(e) => setInfraestructura(e.target.value)} // Asigna el valor ingresado a la variable fecha
              required
            />
          </div>
          <div>
            <label>{textos.columna4}:</label>
            <textarea
              type="text"
              onChange={(e) => setQue_hacemos(e.target.value)} // Asigna el valor ingresado a la variable fecha
              required
            />
          </div>
          {/*
          <div className="selectorImagen">
            <label>{textos.columna4}:</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange} // Asigna la imagen seleccionada a la variable imagen
              required
            />
          </div>
          {previewUrl && (
            <div>
              <label>Vista previa de la imagen:</label>
              <img
                src={previewUrl}
                alt="Vista previa"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          )}
            */}
          <div className="submitCancel">
            <button onClick={closeModal}>Cancelar</button>
            <button type="submit">Añadir</button>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Formulario de Edición"
        className="modalDashboard"
        overlayClassName="modal-overlay">
        <form onSubmit={handleEditSubmit}>
          <h1>Editar</h1>
          <div>
            <label>{textos.columna1}:</label>
            <textarea
              type="text"
              value={editPresentacion}
              onChange={(e) => setEditPresentacion(e.target.value)}
              required
            />
          </div>
          <div>
            <label>{textos.columna2}:</label>
            <textarea
              type="text"
              value={editHistoria}
              onChange={(e) => setEditHistoria(e.target.value)}
              required
            />
          </div>
          <div>
            <label>{textos.columna3}:</label>
            <textarea
              type="text"
              value={editInfraestructura}
              onChange={(e) => setEditInfraestructura(e.target.value)}
              required
            />
          </div>
          <div>
            <label>{textos.columna4}:</label>
            <textarea
              type="text"
              value={editQue_hacemos}
              onChange={(e) => setEditQue_hacemos(e.target.value)}
              required
            />
          </div>
          {/*
          <div>
            <label>{textos.columna4}:</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleEditImageChange}
            />
          </div>
          {previewUrl && (
            <div>
              <label>Vista previa de la imagen:</label>
              <img
                src={previewUrl}
                alt="Vista previa"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          )}
          */}
          <div className="submitCancel">
            <button onClick={closeEditModal}>Cancelar</button>
            <button type="submit">Guardar Cambios</button>
          </div>
        </form>
      </Modal>
      <table className="crud-table">
        <thead>
          <tr>
            <th>{textos.columna1}</th>
            <th>{textos.columna2}</th>
            <th>{textos.columna3}</th>
            <th>{textos.columna4}</th>
            <th>Acciones</th>
            <th>Mover</th>
          </tr>
        </thead>
        <tbody className="crudBody">
          {items.map((item, index) => (
            <tr key={item.id}>
              <td className="cuadroJustificado">{item.presentacion}</td>
              <td className="cuadroJustificado">{item.historia}</td>
              <td className="cuadroJustificado">{item.infraestructura}</td>
              <td className="cuadroJustificado">{item.que_hacemos}</td>
              <td className="editarEliminar">
                <button onClick={() => handleEdit(item)}>Editar</button>
                <button onClick={() => handleDelete(item.id)}>Eliminar</button>
              </td>
              <td>
                <button onClick={() => moveItem(index, "up")}>↑</button>
                <button onClick={() => moveItem(index, "down")}>↓</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default App;
