import React, { useState, useEffect } from "react";
import "../css/Panel_Monitoreo.css";
import axios from "axios";
import region from "../images/region de coquimbo.png";
import provincia1 from "../images/provincia_elqui.png";
import provincia2 from "../images/provincia_limari.png";
import provincia3 from "../images/provincia_choapa.png";
import fondo1 from "../images/fondo_elqui.png";
import fondo2 from "../images/fondo_limari.png";
import fondo3 from "../images/fondo_choapa.png";
import icono1 from "../images/icono_elqui.png";
import icono2 from "../images/icono_limari.png";
import icono3 from "../images/icono_choapa.png";

function Panel_Monitoreo() {
  return (
    <div className="panelMonitoreo">
      <a className="panelRegion" href="/visualizadores">
        <img src={region}></img>
      </a>
      <div className="panelZonas">
        <h1>Monitoreo de uso de suelo agrícola</h1>
        <h2>2024</h2>
        <p>
          La sección representa la información de la dinámica de la superficie
          cultivada de la región, con énfasis en las zonas de bajo riesgo. En
          esta sección encontrará la evolución de la superficie cultivada a
          partir de la temporada 2000 a la última temporada finalizada, con el
          desglose en 4 clases de cultivos (frutales, caducos, frutales
          persistentes, cultivos de ciclo corto y praderas). La información se
          representa en diferentes unidades territoriales.
        </p>
        <h1>Selección de zona</h1>
        <h2>Provincias de la Región de Coquimbo</h2>
        <div className="provincias">
          <a href="/visualizadores">
            <div className="fondoProvincia">
              <img src={provincia1}></img>
              <img src={fondo1}></img>
            </div>
            <img className="iconoProvincia" src={icono1}></img>
          </a>
        </div>
        <div className="provincias">
          <a href="/visualizadores">
            <div className="fondoProvincia">
              <img src={provincia2}></img>
              <img src={fondo2}></img>
            </div>
            <img className="iconoProvincia" src={icono2}></img>
          </a>
        </div>
        <div className="provincias">
          <a href="/visualizadores">
            <div className="fondoProvincia">
              <img src={provincia3}></img>
              <img src={fondo3}></img>
            </div>
            <img className="iconoProvincia" src={icono3}></img>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Panel_Monitoreo;
